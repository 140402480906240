import React,{ useEffect, useRef, useState} from 'react'
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence} from "framer-motion";
import { useForm,SubmitHandler, useWatch } from 'react-hook-form';
import Api from '../../services/Api';
import {  useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import toast from "react-hot-toast";
import Erreurs from '../Erreurs/Erreurs';
function PayVirement({ isOpen,setIsOpen,setIsPayer,isPayer,id }) {
    const [preview, setPreview] = useState(null);
    const modalRef = useRef();  
    const[ isLoading,setIsLoading ]= useRecoilState(loadingState);
    const { register, handleSubmit,reset,setValue,formState: { errors } } = useForm(); 
    const [ erreurs,setErreurs ] = useState([]);
    const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';
    const api = new Api();
    const [paiement,setPaiement ] = useState('');
    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate the file type before previewing
            if (!["image/jpeg", "image/png"].includes(file.type)) {
              alert("Only JPEG or PNG files are allowed");
              return;
            }
            // Set preview URL
           // setValue('imagePayment',event.target.files);
            setPreview(URL.createObjectURL(file));
        }
    }
    const closeModal = (e) => {
      if (modalRef.current === e.target) {
        setIsOpen(false);
      }
    };
    const getPaiements = async(id) => {
        setIsLoading(true);setErreurs([])
        const { status,messages,data } = await api.apiData("get",`/paiement/ct/getpaiement/${id}`);
        setIsLoading(false);
        if( status === 200 ){
            setPaiement(data);
        }
        else {
            setErreurs(messages);
        }
    }
    useEffect(() => {
       setValue('id',id);
       if(!isPayer) {
         getPaiements(id)
       }
    },[isOpen])  
  const submitPay = async (_data) => {
     setIsLoading(true);
     var formdata = new FormData();
     formdata.append('id',_data.id);
    formdata.append('imagePayment',_data.imagePayment[0]);
     const { status,messages,data} =  await api.apiData("post","/paiement/ct/solder",formdata);
  
    setIsLoading(false);
    if(status === 200 ){
        toast.success("Virement de crédit payé.");
        setIsOpen(false);
    }
    else{
        setErreurs(messages);
    }
  }

  return (
    <AnimatePresence>
       {isOpen && (
         <div className="modal-component" onClick={closeModal} ref={modalRef}>
            <motion.div initial={{ opacity: 0, top: "50%" }} animate={{ opacity: 1, top: "50%" }} transition={{ duration: 0.2 }} exit={{ opacity: 0, top: "10%" }}
                className="modal">
                <div className="close" onClick={() => setIsOpen(false)}>
                <FaRegWindowClose id="close-icon" />
                </div>
            <h4>{isPayer ? "Ajouter un réçu de paiement":"Voir le reçu de paiement."}</h4>
           {isPayer ? 
            <form onSubmit={handleSubmit(submitPay)}>
               <label>Reçu de virement
                <input type="file"
                  accept="image/jpeg, image/png"
                  
                  id="imagePayment"
                  {...register("imagePayment", {
                   required: "Veuillez Télécharger la preuve de Paiement.",
                    validate: {
                      acceptedFormats: (files) =>
                        files[0]?.type === "image/jpeg" || files[0]?.type === "image/png" ||
                        "Fichier autorisé: jpeg ou png",
                     checkFileSize: (files) =>
                        files[0] && files[0].size <= 2 * 1024 * 1024 || // Max 2 MB
                        "La taille ne doit pas excédée 2MB.",     
                    },
                  })}
                  onChange={(event) => onFileChange(event)}
                 />
                 {errors.imagePayment && (
                    <span className="error-msg">{ errors.imagePayment.message }</span>
                   )}
               </label> 
               <label>
                 {preview && (
                    <div>
                     <p>Reçu de paiement </p>
                     <img src={preview} alt="Preview" style={{ maxWidth: "520px", maxHeight:"350px", marginTop: "15px" }} />
                    </div>
                  )}
               </label>
               <Erreurs validation={erreurs} />
               <button type="submit">Enregistrer</button>
            </form>:
            <div>
               {paiement && <img src={ url + paiement.receiptpath } alt='receipt-img'  
               style={{ maxWidth: "500px", maxHeight:"320px", marginTop: "10px" }} />}
                <Erreurs validation={erreurs} />
                <button type="submit" className='delete' style={{ marginTop:"20px"}} onClick={() => setIsOpen(false)}>Fermer</button>
            </div>
            }
          </motion.div>
         </div>)
       }
    </AnimatePresence>
  )
}

export default PayVirement