import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence} from "framer-motion";
import { FaRegWindowClose } from 'react-icons/fa';
import {  useForm } from "react-hook-form";
import { FirstUpperCase, isEmpty, objecttoFormData } from '../../services/Helpers/fonctions';
import Erreurs from '../Erreurs/Erreurs';
import Api from '../../services/Api';
const UpdateOrganisation = ({isOpen,setIsOpen,data,setData,setIsLoading})=> {
    const modalRef = useRef();
    const inputRef = useRef(null);
    const [erreurs,setErreurs] = useState([]);
    const api = new Api();
    const formRef = useRef(null);
    const { register, handleSubmit,reset, formState: { errors } } = useForm({
        defaultValues:
            data
        });
  const closeModal = (e) => {
     if (modalRef.current === e.target) {
          setIsOpen(false);
     }
  };
  const handleInput = (e) => {
     setData({...data,[e.target.name]:e.target.value})
  }
  const submitData = async () => {
    setErreurs([]);setIsLoading(true);
    var formdata =  objecttoFormData(data);
    const { status,messages,responseData } = await api.apiData('patch',`/organisation/update/${data.id}`,data);
    setIsLoading(false);
    if( status !== 200 ){
       setErreurs(messages);
    }else{
      setIsOpen(false);formRef.current.reset();
     }  
  }
  useEffect(() => {
    if(inputRef?.current)
      inputRef.current.value = data.designation;
  },[isOpen]);
  return (
    <AnimatePresence>
      {(isOpen) && (
      <div className="modal-component" onClick={closeModal} ref={modalRef}>
        <motion.div initial={{ opacity: 0, top: "30%" }} animate={{ opacity: 1, top: "50%" }} transition={{ duration: 0.2 }} exit={{ opacity: 0,   top: "10%" }}
          className="modal">
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose id="close-icon" />
              
            </div>
            <h4>Mettre à jour une organisation ou institution</h4>
            {!isEmpty(data)&&
             <form onSubmit={handleSubmit(submitData)} ref={formRef}>
               <label>Type d'organisation
                    <select value={data.type_organisation} name="type" id="type"
                      {...register('type_organisation', {
                        onChange: (e) => {
                          handleInput(e)
                        },
                        validate: (value) => value !==  ""
                        })
                      }
                    >
                    <option value=''>Selection le type d'organisation</option>
                    <option value="société">Société</option>
                    <option value="gouvernement">Gouvernement</option>
                    <option value="institution">Institution</option>
                    </select>
                    {errors.type_organisation && (
                          <span className="error-msg">Selectionner le type d'organisation.</span>
                        )}
                  </label>
                  <label>Désignation
                  <input type="text"
                     value={FirstUpperCase(data.designation)} 
                     name='designation' 
                     placeholder={`Désignation de ${data.type_organisation}`} 
                     autoComplete='false'
                    {...register('designation', {
                      onChange: (e) => {
                          handleInput(e)
                      },
                      required:true,minLength:3},
                    )}
                    ref={(e) => {
                      register("designation").ref(e); // Associer useForm à useRef
                      inputRef.current = e; // Stocker la référence dans inputRef
                    }}
                  />
                  {errors.designation && errors.designation.type === "required" && (
                    <span role="alert" className="error-msg">
                      Champ obligatoire.
                    </span>
                  )}
                  {errors.designation && errors.designation.type === "minLength" && (
                    <span role="alert" className="error-msg">
                      Le nombre minimum de caractères est trois (3).
                    </span>
                  )}
                  </label>
                  <label>Sigle
                    <input type="text"
                      value={data.sigle} 
                      name='sigle' 
                      placeholder={`Le sigle de ${data.type}`} 
                      autoComplete='false'
                      {...register('sigle', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        required:true,minLength:1,maxLength:10},
                      )}
                    
                    />
                    {errors.sigle && errors.sigle.type === "required" && (
                      <span role="alert" className="error-msg">
                        Champ obligatoire.
                      </span>
                    )}
                    {errors.sigle && errors.sigle.type === "minLength" && (
                      <span role="alert" className="error-msg">
                        Le nombre minimum de caractères est trois (3).
                      </span>
                    )}
                  </label>
                  <label>Domaine
                    <input type="text"
                      value={data.domaine} 
                      name='domaine' 
                      placeholder={`Le domaine d'intervention de  ${data.type}`} 
                      autoComplete='false'
                      {...register('domaine', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        required:false,minLength:2},
                      )}
                    
                    />
                    {errors.domaine && errors.domaine.type === "required" && (
                      <span role="alert" className="error-msg">
                        Champ obligatoire.
                      </span>
                    )}
                    {errors.domaine && errors.domaine.type === "minLength" && (
                      <span role="alert" className="error-msg">
                        Le nombre minimum de caractères est trois (3).
                      </span>
                    )}
                  </label>
                  <Erreurs validation = {erreurs} />
                  <button type="submit">Enregistrer</button>
            </form> }   
         </motion.div>    
      </div>
     )}
  </AnimatePresence>  
  )   
}

export default UpdateOrganisation;