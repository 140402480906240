import React, { useState } from 'react';
import { Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import Api from '../../services/Api';
import * as XLSX from 'xlsx';
import { formatStringNumber } from '../../services/Helpers/fonctions';
import moment from 'moment';
function ToolbarVadis({ seachData,filterData,isLoading,setIsLoading }) {
    const [ erreurs,setErreurs ] = useState([]);
    const api = new Api();
    const onExportClick = async () => {
       
        var formdata = new FormData();
        setErreurs([]);setIsLoading(true);
        var url = `/paiement/ct/exporter`;
        if( seachData?.startDate || seachData?.endDate ){
           formdata.append('filterDate',JSON.stringify(seachData));
        }
        else if( filterData.items.length > 0){
            formdata.append('filterData',JSON.stringify(filterData));
        }
       const { status,data,errors} = await api.apiData("post",url,formdata);
        console.log(data)
        setIsLoading(false)
        if( data.length > 0 ){
            const workbook = XLSX.utils.book_new();
            const _data = data.map((dt,index) => {
                 return{
                    Ordre: index + 1,
                    Categorie: dt.name,
                    chassis: dt.chassis,
                    reference:dt.reference,
                    "Type Client": dt.typeClient.charAt(0).toUpperCase() + dt.typeClient.slice(1),
                    "numéro Immat":dt.numImmat,
                    FullName: (dt.typeClient === "société" || dt.typeClient === "gouvernement") ? dt.organisation : dt.nomComplet,
                    Telephone: dt.telephone,
                    Email:dt.email ? dt.email:"Non fournie",
                    "Mode paiement": dt.mode_paiement.charAt(0).toUpperCase() + dt.mode_paiement.slice(1),
                    Montant: formatStringNumber(dt.price),
                    Status: dt.status,
                    Agent: dt.prenom + " "+ dt.nomUser,
                    Date:  moment(dt.pupdated_at).format("Do MMM YYYY")
                 }
            });
          const worksheet = XLSX.utils.json_to_sheet(_data);
            // Add worksheet to workbook
           XLSX.utils.book_append_sheet(workbook, worksheet, 'Stattistique');
           XLSX.writeFile(workbook, 'export_' + moment().format('dddd-Do-MMMM-YYYY')  + '.xlsx');
        }
    }
    return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />  
          <Button
            // startIcon={<SaveAltIcon />}
            onClick={onExportClick}
           
          >
           Exporter
          </Button>
          {/* <Button onClick={() => setDisabled((state) => !state)}>
            {disabled ? "Enable" : "Disable"} export button
          </Button> */}
        
        </GridToolbarContainer>
      );
}
export default ToolbarVadis;