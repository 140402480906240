import React, { useContext, useEffect, useRef, useState } from 'react'
import InvoiceDetailsVadico from '../../components/InvoiceDetailsVadico/InvoiceDetailsVadico';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import Api from '../../services/Api';
import Erreurs from '../../components/Erreurs/Erreurs';
import { UserContext } from '../../services/Context/Context';
import { toast } from 'react-hot-toast';
import Swal from "sweetalert2";
const PaymentDetailsVadico = () => {
  const componentRef = useRef();
  const [ paiement,setPaiement ] = useState({});
  const { id } = useParams();
  const [isLoading , setIsLoading ] = useRecoilState(loadingState);
  const [ erreurs,setErreurs ] = useState([]);
  const { privileges } = useContext(UserContext);
  const navigate = useNavigate();
  const api = new Api();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // console.log(paiement);
  const IsValidateReceiptPrivilege = () => {
    return privileges.filter((p) => p.privilege === "Annuler Paiement").length > 0 ;
 }
 const annulerPaiement = async (id) => {
  Swal.fire({
    title: 'Êtes-vous sûr?',
    text: "d'annulez le paiement",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: `Oui,Annuler`,
    cancelButtonText: "Fermer"
  }).then((result) => {
    if(result.isConfirmed){
      setIsLoading(true);
       api.apiData("put",`/paiement/ct/invalidate/${id}`).then((response) => {
        setIsLoading(false);
       if( response.status === 200 ){
          toast.success("Paiement annulé avec succès.");
          navigate("/ct/paiement/list");
       }
       else {
        toast.error("Operation d'annulation non effectuée.")
        }
      });
     
    }
  });
   
 }
  useEffect(() => {
     const getPaiement = async (id) => {
      setIsLoading(true);setErreurs([]);
      const { status, messages, data } = await api.apiData('get',`/paiement/ct/getpaiement/${id}`);
      setIsLoading(false);
      if( status !== 200 ){
         setErreurs(messages);
      }
      else{
        setPaiement(data);
      }
    
     }
   getPaiement(id)
  },[id]);
  return (
    <div className='payment-invoice page'>
       <div className="left" style={{ marginBottom:'10px'}}>
           {/* {paiement && */}
            <button className="print-btn" onClick={handlePrint}>
              Imprimer
            </button>
            <button className='primary' style={{ marginLeft:10}} onClick={(() => navigate("/ct/paiement/nouveau"))}>
                Nouveau
            </button>
            {IsValidateReceiptPrivilege() && 
             <button className='secondary' style={{ marginLeft:10}} onClick={() =>annulerPaiement(id)}>
                 Annuler le Paiement
             </button>
            }
        </div> 
        <Erreurs validation={erreurs}/>   
       <InvoiceDetailsVadico paiement={paiement} ref={componentRef} />
    </div>
  )
}

export default PaymentDetailsVadico