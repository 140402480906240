import { useEffect,useState} from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./styles/App.scss";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Payment from "./pages/Payment/Payment";
import PaymentList from "./pages/PaymentList/PaymentList";
import PaymentListOther from "./pages/PaymentListOther/PaymentListOther";
import PaymentDetails from "./pages/PaymentDetails/PaymentDetails";
import UsersList from "./pages/UsersList/UsersList";
import OtherPayment from "./pages/OtherPayment/OtherPayment";
import PaymentResubmission from "./pages/PaymentResubmission/PaymentResubmission";
import OtherPaymentResubmission from "./pages/OtherPaymentResubmission/OtherPaymentResubmission";
import RejectedPaymentList from "./pages/RejectedPaymentList/RejectedPaymentList";
import UserSettings from "./pages/UserSettings/UserSettings";
import SideMenu from "./components/SideMenu/SideMenu";
import LoginProtected from "./services/Auth/LoginProtected";
import Connected from "./services/Auth/Connected";
import Spinner from "./components/Spinner/Spinner";
import Api from "./services/Api";
import { UserContext,ElementContext } from "./services/Context/Context";
import { Toaster } from 'react-hot-toast';
import RolesList from "./pages/RolesList/RolesList";
import Agencies from "./pages/Agencies/Agencies";
import Ward from "./pages/Ward/Ward";
import Stats from "./pages/Stats/Stats";
import AutorisedPaymentList from "./pages/AutorisedPaymentList/AutorisedPaymentList";
import HistoriqueList from "./pages/HistoriqueList/HistoriqueList";
//DataGrid Pro
import { LicenseInfo } from '@mui/x-license-pro';
import Organisation from "./pages/Organisation/Organisation";
import PaymentDetailsOrganisation from "./pages/PaymentDetailsOrganisation/PaymentDetailsOrganisation";
import PaymentListOrganisation from "./pages/PaymentListOrganisation/PaymentListOrganisation";
import PaiementCertificat from "./pages/PaiementCertificat/PaiementCertificat";
import PaymentCertificatDetails from "./pages/PaymentCertificatDetails/PaymentCertificatDetails";
import PaiementCertificatList from "./pages/PaiementCertificatList/PaiementCertificatList";
import PaiementCmc from "./pages/PaiementCmc/PaiementCmc";
import PaymentCMCDetails from "./pages/PaymentCMCDetails/PaymentCMCDetails";
import PaiementCMCList from "./pages/PaiementCMCList/PaiementCMCList";
import PaiementListeCMCDouane from "./pages/PaiementListeCMCDouane/PaiementListeCMCDouane";
import Listechassisdouane from "./pages/Listechassisdouane/Listechassisdouane";
import Statjourcategorie from "./pages/CMCStatistiques/Statjourcategorie";
import StatistiqueParCategorie from "./pages/CMCStatistiques/StatistiqueParCategorie";
import moment from "moment";
import PaiementVadico from "./pages/PaiementVadico/PaiementVadico";
import PaymentDetailsVadico from "./pages/PaymentDetailsVadico/PaymentDetailsVadico";
import ListeOrganisation from "./pages/ListeOrganisation/ListeOrganisation";
import NouvelleOrganisation from "./components/NouvelleOrganisation/NouvelleOrganisation";
import PaymentListVadis from "./pages/PaymentListVadis/PaymentListVadis";
// import { useIdleTimer } from 'react-idle-timer';

function App() {
  //Datagrid license key
  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_KEY);

  //Idle timer
  // const timeout = 3000
  // const onIdle = () => {
  //   console.log('idle detected')
  // }

  // const idleTimer = useIdleTimer({ onIdle })
  moment.suppressDeprecationWarnings = true;
  const navigate = useNavigate();
  const [user,setUser] = useState('');
  const [userRole,setUserRole] = useState('');
  const [agence,setAgence] = useState('');
  const [elementsData,setElementsData] = useState();
  const [decoupage,setDecoupage] = useState();
  const[privileges,setPrivileges] = useState([]);
  const [changePrivilege,setChangePrivilege] = useState(false);
  const [ typeOrganisations ,setTypeOrganisations ] = useState([]);
  const [ organisations,setOrganisations ] = useState([]);
  const api = new Api();
  //localStorage.removeItem('token');
  const { pathname } = useLocation();
 
  useEffect(() => {
    setPrivileges([]);
   if(  pathname !== "/douane/consultationcms/liste") {
     api.apiData('get','/user').then((resp) => {
      if(resp?.status !== 200){
        localStorage.removeItem('token');navigate('/');
      }else {
        setUser(resp?.user);
        
        setUserRole(resp?.role);setAgence(resp?.agence);
        api.apiData('get','/paiement/getelements').then((resp) => {
         // console.log(resp)
          if(resp.status == 200)
            setElementsData(resp.Elements);
           
          
        });
        api.apiData('get','/decoupage/getAll').then((resp) => {
           if(resp?.status == 200){
             setDecoupage({regions:resp.regions,prefectures:resp.prefectures,communes:resp.communes,quartiers:resp.quartiers})
           }
        });
        api.apiData('get','/roles/getUserPrivileges').then((resp) => {
      
          if(resp?.status == 200){
            setPrivileges(resp.privileges.map((privilege) => {
                return {
                  privilege_id:privilege.privilege_id,
                  privilege:privilege.privilege
                }
              })); 
          }
       });
   
      }
    
     if(resp?.user?.nbre_cnx === 1){
       navigate('myaccount/settings');
     } 
     });
    }
   
  },[changePrivilege]);
 
  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={false} />
      { pathname !== "/douane/consultationcms/liste" ?
       <>
        <UserContext.Provider value={{ user,setUser,userRole,agence,decoupage,privileges,changePrivilege,setChangePrivilege }} >
         
              <Navbar />
              <SideMenu />
              <Spinner />
              <ElementContext.Provider value={{elementsData}} >
                  <Routes>
                    <Route path="*" element={<h2 style={{ textAlign:'center'}}>Pas de Page Correspondate: 404!</h2>} />
                    <Route path="/" element={<Connected><Login /></Connected>} />
                    <Route path="dashboard" element={<LoginProtected><Dashboard /></LoginProtected>} />
                    <Route path="stats" element={<LoginProtected><Stats /></LoginProtected>} />
                    <Route path="payment">
                      <Route path="new" element={<LoginProtected><Payment /></LoginProtected>} />
                      <Route path="organisation" element={<LoginProtected><Organisation /></LoginProtected>}/>
                      <Route path="other/new" element={<LoginProtected><OtherPayment /></LoginProtected>} />
                      <Route path="list" element={<LoginProtected><PaymentList /></LoginProtected>} />
                      <Route path="rejected/list" element={<LoginProtected><RejectedPaymentList /></LoginProtected>} />
                      <Route path="autoriser/list" element={<LoginProtected><AutorisedPaymentList /></LoginProtected>} />
                      <Route path="other/list" element={<LoginProtected><PaymentListOther /></LoginProtected>} />                
                      <Route path="invoice/:id" element={<LoginProtected><PaymentDetails /></LoginProtected>} />
                      <Route path="invoice/organisation/:id" element={<LoginProtected><PaymentDetailsOrganisation /></LoginProtected>} />
                      <Route path="historique/list" element={<LoginProtected><HistoriqueList /></LoginProtected>} />
                      <Route path="organisation/list" element={<LoginProtected><PaymentListOrganisation /></LoginProtected>} />
                    </Route> 
                    <Route path="resubmission">
                      <Route path="new/:id" element={<LoginProtected><PaymentResubmission /></LoginProtected>} />
                      <Route path="other/new" element={<LoginProtected><OtherPaymentResubmission /></LoginProtected>} />
                      {/* <Route path="list" element={<LoginProtected><PaymentList /></LoginProtected>} /> */}
                    </Route> 
                    <Route path="users">
                      <Route path="list" element={<LoginProtected><UsersList /></LoginProtected>} />
                      <Route path="roles/list" element={<LoginProtected><RolesList /></LoginProtected>} />
                    </Route>
                    <Route path="agencies">
                      <Route path="list" element={<LoginProtected><Agencies /></LoginProtected>} />
                    </Route>
                    <Route path="wards">
                      <Route path="list" element={<LoginProtected><Ward /></LoginProtected>} />
                    </Route>
                    <Route path="myaccount">
                      <Route path="settings" element={<UserSettings />} />
                    </Route>
                    <Route path="certificatperte">
                      <Route path="nouveau" element={<PaiementCertificat />}></Route>
                      <Route path="invoicedetails/:id" element={<PaymentCertificatDetails />}></Route>
                      <Route path="list" element={<LoginProtected><PaiementCertificatList /></LoginProtected>}></Route>
                    </Route>
                    <Route path="ct/paiement">
                       <Route path="nouveau" element={<PaiementVadico />}></Route>
                       <Route path="facture/:id" element={<PaymentDetailsVadico />}></Route>
                       <Route path="list" element={<PaymentListVadis />}></Route>
                    </Route>
                    <Route path="cmc">
                      <Route path="nouveau" element={<PaiementCmc />}></Route>
                      <Route path="details/:id" element={<PaymentCMCDetails />}></Route>
                      <Route path="listpaiement" element={<PaiementCMCList />}></Route>
                      <Route path="douane/listecharge" element={<Listechassisdouane />}></Route>
                      <Route path="statistiques">
                         <Route path="parjourcategorie" element={<Statjourcategorie />}></Route>
                         <Route path="parcategorie" element={<StatistiqueParCategorie />}></Route>
                      </Route>
                    </Route>  
                    <Route path="organisation">
                       <Route path="list" element={<ListeOrganisation />}></Route>
                       {/* <Route path="nouvelle" element={<NouvelleOrganisation />}></Route> */}
                    </Route>
                  </Routes>
              </ElementContext.Provider> 
           
          <Footer />
         </UserContext.Provider>
      </>   
     :  <>  
        <Spinner />
        <Routes>
           <Route path={pathname.slice(1)} element={<PaiementListeCMCDouane />}></Route>
        </Routes>
    </>}
    </div>
  );
}

export default App;