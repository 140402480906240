import React, { useEffect, useState } from 'react'
import BarChartComponent from '../BarChartComponent/BarChartComponent'
import BarCharts from './Charts/BarCharts'
import Api from '../../services/Api';

function GraphBoxVadis() {
  const[data,setData ] = useState([]);
  const api = new Api();
  useState(() => {
    const getStats = async () => {
          const  response  = await api.apiData("get","/paiement/ct/stats");
          if( response.status === 200 ){
              setData (response.data)       
          }
    }
   
    getStats();
    
  },[]);
  if( data.length === 0  ) return null;
  return (
    <>
       <h3>Graphs de paiements des contrôles techniques</h3>
       <div className="chart-and-array">
            <div className="chart">
              <BarCharts 
                graphData={data?.payByMonthPaye}
                legende="Totaux des montants payés"
                titre= "Tataux des paiements"
              />
            </div>
            <div className="chart">
              <BarCharts 
                 graphData={data?.payByMonthNonPaye}
                 legende="Totaux des montants non payés"
                 titre= "Tataux des paiements"
              />
            </div>
       </div>
    </>   
  )
}

export default GraphBoxVadis