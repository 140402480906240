import React, { useEffect, useState } from 'react'
import Api from '../../services/Api';
import StatsBox from '../StatsBox/StatsBox';
import { formatStringNumber } from '../../services/Helpers/fonctions';

function StatBoxVadis({ statsPaye , statsnonPaye}) {
 
  
  useEffect(() => {
 
  },[])  
  return (
    <>
        <div className="boxes">
            <StatsBox
                title={"Paiements effectués"}
                amount={ formatStringNumber(statsPaye) }
                link={"/ct/paiement/list"}
                position={1}
                stat={{ mode_paiement: "cash",}}
            />
            <StatsBox
                title={"Paiements non effectués"}
                amount={ formatStringNumber(statsnonPaye) }
                link={"/ct/paiement/list"}
                position={1}
                stat={{ mode_paiement: "virement crédit",}}
            />
        </div>
   </> 
  )
}

export default StatBoxVadis;