import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { ElementContext } from '../../services/Context/Context';
import { formatStringNumber } from '../../services/Helpers/fonctions';
export default function StepOne({ nextStep,paiement,setPaiement }) {
  const { elementsData } = useContext(ElementContext);
  const [ selectedOrganisations,setSelectedOrganisations ] = useState([]);
  const { Organisations } = elementsData ? elementsData :{};
  const { prixCategories } = elementsData ? elementsData : {}
  const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {paiement},});  
  const handleInput = (e) => {  
    if( e.target.name === 'typeClient' ){
        if ( e.target.value !== 'particulier' ){
         setSelectedOrganisations(Organisations?.filter((p) => p.type_organisation === e.target.value));
         setPaiement({...paiement,[e.target.name]:e.target.value});
        }else{
          if( paiement.mode_paiement === "virement bancaire"){
            setPaiement({...paiement,[e.target.name]:e.target.value,organisation_id:'',mode_paiement:'cash'});
          }
          else{
            setPaiement({...paiement,[e.target.name]:e.target.value,organisation_id:''});}
      }
    }
    else if(e.target.name === "categorie_id"){
       if( e.target.value !== "" ){
        const arr = prixCategories.filter((p) => p.prix_categorie_id === parseInt(e.target.value));
        if( arr.length > 0 ){
          setPaiement({...paiement,[e.target.name]:e.target.value,prix:arr[0].price,nomCategorie: arr[0].name });
        }
        else {
          setPaiement({...paiement,[e.target.name]:e.target.value,prix:0});
        }
      }
    }
    else{
       setPaiement({...paiement,[e.target.name]:e.target.value});
   }
  } 
  useEffect(() => {
    setSelectedOrganisations(Organisations?.filter((p) => p.type_organisation === paiement.typeClient));
  },[])
  return (
    <div className="step-component">
      <h4>Informations du client et du véhicule</h4>
      <form onSubmit={handleSubmit(nextStep)}>
         <div className="input-group">
            <label className='obligatoire'>
             Type de client
             <select
              name="typeClient"
              id="typeClient"
              {...register("typeClient", {
                onChange: (e) => {
                  handleInput(e);
                },
                validate: (value) => value !== "",
              })}
              value={paiement.typeClient}
             >
              <option value="" key={"typeClient"}>
                Selectionnez le type de client
              </option>
              <option value="particulier" key={"particulier"}>
                Particulier
              </option>
               <option value="société" key={"societe"}>
                Société
              </option>
              <option value="gouvernement" key={"Gouvernement"}>
                Gouvernement
              </option>
            </select>
            {errors.typeClient && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}
            </label> 
            <label className='obligatoire'>
             {paiement.typeClient === "Société" && 
                "Nom de la société"}
              {paiement.typeClient === "particulier" &&  "Prénom et Nom"}
              {paiement.typeClient === "gouvernement" &&  "Nom du ministère ou structure"}
              {paiement.typeClient === "société" &&  "Nom de la société"}
              {paiement.typeClient === "" &&  <span style={{ marginTop:22}}></span>}
              {paiement.typeClient === "particulier" ?
              
               <>
                  <input
                    value={paiement.nomComplet}
                    type="text"
                    name="nomComplet"
                    key="nomComplet"
                    placeholder="Nom et Prenom du client"
                    
                    id="nomComplet"
                    {...register("nomComplet", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      maxLength: 150,
                      minLength: 2,
                    })}
                  />
                  {errors.nomComplet && errors.nomComplet?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                  )}
                  {errors.nomComplet && errors.nomComplet?.type === "minLength" && (
                    <span className="error-msg">
                      Ne peut pas être inférieur à 2 caractères.
                    </span>
                  )}
                  {errors.nomComplet && errors.nomComplet?.type === "maxLength" && (
                    <span className="error-msg">
                      Ne peut pas être supérieur à 150 caractères.
                    </span>
                  )}
                </>
                :
                <>
                <select
                 name='organisation_id'
                  {...register("organisation_id", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                 value={paiement?.organisation_id}
                 >
                  <option value="">---</option>
                  {selectedOrganisations?.length > 0 && selectedOrganisations.map((organisation) => (
                    <option value={organisation.organisation_id} key={organisation.organisation_id}
                    >{ organisation.nom }</option>
                  ))}
                </select>
                 {errors.organisation_id && (
                  <span className="error-msg">
                     Ce champs est obligatoire.
                  </span>
                 )}
                 </>
              }  
         
            </label>     
         </div>
         <div className='input-group'>
           <label className='obligatoire'>
              Téléphone
              <input 
               type='tel'
               name='telephone' 
               placeholder="Numéro de téléphone(ex: 620000000)"
               value={paiement.telephone}
               {...register("telephone", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: true,
                maxLength: 9,
                minLength: 5,
                pattern: /6[0-9]{8}$/g,
              })}
              />
              {errors.telephone && errors.telephone?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.telephone && errors.telephone?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: 620000000
                </span>
              )}
          
           </label>
           <label>
             Email
             <input type='text' name="email" placeholder='Email (ex:example@example.com)' 
               value={ paiement.email }
               {...register("email", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: false,
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              })}
             />
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: example@example.com
               </span>
              )}
           </label>
         </div>
         <div className='input-group'>
           <label className='obligatoire'>
              Numéro de Chassis
              <input
                value={paiement.chassis?.toUpperCase()}
                type="text"
                name="chassis"
                key="chassis"
                placeholder="N° d'Immatriculation"
                {...register("chassis", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    maxLength: 150,
                    minLength: 2,
                  })}
               /> 
              {errors.chassis && errors.chassis?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.chassis && errors.chassis?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.chassis && errors.chassis?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
           </label> 
           <label>
              Numéro de Immatriculation
              <input
                value={paiement.numImmat?.toUpperCase()}
                type="text"
                name="numImmat"
                key="numImmat"
                placeholder="ex: AA-0001-02"
                {...register('numImmat', {
                onChange: (e) => {
                    handleInput(e)
                },
                pattern:{
                    value:/^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/,
                    message:"Format Incorrect.ex: AA-0001-02"
                   }
                 },
               )}
                
               /> 
            
              {errors.numImmat  && (
                <span className="error-msg">
                   Format incorrect d'immatriculation incorrect.ex:AA-0001-02
                </span>
              )}
           </label> 
         </div>
         <div className='input-group'>
            <label className='obligatoire'>
                Catégorie
                <select
                  name='categorie_id'
                  {...register("categorie_id", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                  value={paiement.categorie_id}
                 >
                 <option value="">---</option>
                  { prixCategories?.length > 0 && prixCategories.map((categorie) => (
                    <option value={categorie.prix_categorie_id} key={categorie.prix_categorie_id}>
                      { categorie.name }
                    </option>))}
                </select>
                {errors.categorie_id && (
                 <span className="error-msg">
                    Ce champs est obligatoire.
                 </span>
                )}
            </label>
            <label>
                Prix
                <input 
                   disabled={true}
                   value={formatStringNumber(paiement.prix)}
                />
            </label>
            <label>
               Mode Paiement
               <>
               <select
                name='mode_paiement'
                {...register("mode_paiement", {
                  onChange: (e) => {
                  handleInput(e);
                  },
                  validate: (value) => value !== "",
                })}
                value={paiement.mode_paiement}
               >
                 <option value="">--</option>
                 <option value="cash">Cash</option>
                 <option value="virement crédit"
                  disabled={paiement.typeClient === 'particulier'}
                 >Virement Bancaire</option>
               </select>
               {errors.mode_paiement && (
                 <span className="error-msg">
                    Ce champs est obligatoire.
                 </span>
                )}
              </> 
            </label>
         </div>
         <div className="buttons">
           <button type="submit">Suivant</button>
         </div>   
      </form>
    </div>  
  )
}
