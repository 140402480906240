import React from 'react';
import "./CardTable.scss";
function CardTable({ data }) {
  // console.log(data);
  return (
    <div className="cart-table" style={{ overflowX:'unset'}}>
       <table style={{ minWidth:0}}>
          <thead className="table-header">
            <tr>
              <th>Description</th>
              <th>Chassis</th>
              <th>Prix (en fg)</th>
            </tr>
          </thead>
          <tbody>
             <tr>
                <td>{ data?.nomCategorie ? data?.nomCategorie : data.name}</td>
                <td>{ data?.chassis?.toUpperCase() }</td>
                <td>{ data?.prix ? data?.prix :data?.price }</td>
             </tr>
          </tbody>
        </table>  
    </div>
  )
}

export default CardTable;