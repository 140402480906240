import React, { useState } from 'react'
import CardTable from './CardTable/CardTable';
import { ToWords } from "to-words";
import Erreurs from "../Erreurs/Erreurs";
import Api from "../../services/Api";
import "./_Step.scss"
import { formatStringNumber,objecttoFormData } from "../../services/Helpers/fonctions";
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
function StepTwo({ nextStep,prevStep,paiement,setPaiement }) {
  const[ erreurs,setErreurs ] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const api = new Api();
   const toWords = new ToWords({
        localeCode: "fr-FR",
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
        },
   });  
  const payer = async () => {
    if( paiement.organisation_id === "" ) paiement.organisation_id = 0;
    var formData = objecttoFormData(paiement); 
    setErreurs([]);setIsLoading(true);
     const { status, messages, data } = await api.apiData("post","/paiement/ct/nouveau",formData);
     setIsLoading(false);
     if (status != 200) {
       setErreurs(messages);return;
     }else{ 
      setPaiement({
        ...paiement,
        qrcodepath: data.qrpath,
        reference: data.reference,
        paiement_id: data.paiement_id,
      });
       nextStep();
    }
  }  
  console.log(paiement)
  return (
    <div className="step-component">
       <h4>Détails du Paiement</h4>
       <div className="table">
         <CardTable data={paiement} />  
       </div> 
       <div className="total">
          <span>Total</span>
          <div className='chiffre'>
            <strong>{ formatStringNumber(paiement.prix) } fg</strong>
            <strong>{ toWords.convert(paiement.prix) }</strong> Francs Guinéens.
          </div>
        
       </div> 
       <div className="input-group">
          <Erreurs validation={erreurs} />
       </div>
       <div className="buttons">
        <button className="secondary" onClick={prevStep}>
          Précedent
        </button>
        <button className="primary" onClick={payer}>
          Payer
        </button>
      </div>
    </div>
  )
}

export default StepTwo;