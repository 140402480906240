import React,{useEffect,useState,useMemo, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import CartTable from '../CartTable/CartTable';
import {formatStringNumber, isEmpty} from "../../services/Helpers/fonctions";
import moment from "moment";
import 'moment/locale/fr';
import { ToWords } from 'to-words';
import { UserContext } from '../../services/Context/Context';
import Api from '../../services/Api';

const InvoiceDetails = React.forwardRef((props, ref) => {
  const location = useLocation();
  const [modeImma,setModeImma] = useState();
  const {user,agence,decoupage} = useContext(UserContext);

  const[quartier,setQuartier] = useState();
  const [commune,setCommune] = useState();
  const [agenceName,setAgenceName] = useState();
  const [prefecture,setPrefecture] = useState();
  const [logo,setLogo] = useState();
  const [userInfo,setUserInfo] = useState();

  const api = new Api();
  const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';
  const [loadingtest,setLoadingtest] = useState(1)
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const[sumMontant,setSumMontant] = useState(0);
  const [paiementDetail,setPaiementDetail] = useState();
  const [paiementInfo,setPaiementInfo] = useState();
  const{ id } = useParams();
  useEffect(() => {
    setPaiementDetail(props.elements.paiementDetail);
    setPaiementInfo(props.elements.paiementInfo);
    setUserInfo(props.elements.user);
    //console.log(paiementInfo)
    //console.log(props.elements.paiementInfo)
    setLoadingtest(1);
    if(paiementInfo?.agence_id){
      api.apiData('get',`/agence/agencebyid2/${paiementInfo?.agence_id}/${paiementInfo?.quartier_id}`).then((resp) => {
       
        setQuartier(resp.agence.nom_quartier);
        setCommune(resp.agence.nom_commune);
        setPrefecture(resp.agence.nom_prefecture);
        setAgenceName(resp.agence.nom_agence);
        setLogo(resp.agence.logo)
      })
   }
   setLoadingtest(2);
   
  }, [props]);
  
  useMemo(() => {
    let montantplaque = 0;
  
    if(paiementInfo?.document !== "Vignette") {
     if(paiementInfo?.type_plaque === "IT")
        montantplaque = 300000.0;
      else if(paiementInfo?.type_plaque === "EP" || paiementInfo?.type_plaque === "VA")  
        montantplaque = 350000.0;
    }
    setPaiementInfo({...paiementInfo,montantplaque:montantplaque});
   
   // if(!isEmpty(paiementInfo))
     const total = parseFloat(paiementInfo?.cartegrise?.montant!= null ? paiementInfo?.cartegrise?.montant:0) 
     + paiementInfo?.vignette?.montant ? parseFloat(paiementInfo?.vignette?.montant) +  parseFloat(paiementInfo?.vignette?.montant) *  (parseFloat(paiementInfo?.penalites)/100):0
     + parseFloat(paiementInfo?.autorisation?paiementInfo.autorisation?.montant:0) + parseFloat(paiementInfo?.commission) 
     + parseFloat(montantplaque);
     
     setSumMontant(total);
      
    if(paiementDetail?.modeImma == '1')
      setModeImma("Immatriculation")
    else if(paiementDetail?.modeImma == 2)
      setModeImma("Réimmatriculation");
      
  },[paiementInfo?.penalites]);

  return (
    <div className="invoice" ref={ref}>
      { paiementInfo?.paiement_id !== ''? 
      <>
      
        <div className="head">
          <h1>Reçu de Paiement </h1>
          <div className="logo">{logo?<img src={url+logo} style={{width:'85px'}}/>:'LOGO'}</div>
        </div>
        <div>
          <div style={{display:'inline'}}>
            <span style={{fontSize:'17px',fontFamily:'sans-serif',fontStyle:'italic'}}>Numéro de Référence : </span> 
            <span style={{fontWeight:'bold',fontFamily:'revert-layer'}}>{paiementInfo?.reference}</span>
          </div> 
        </div> 
        <div className="bill-details">
          <div className="from">
            <span className="subtitle">Par </span>
            <strong>DSD Guinée</strong>
            <p>Agence de {agenceName} / {quartier}</p>
            <p>{(prefecture !== "KALOUM" && prefecture !== "DIXINN" && prefecture !== "MATAM" && prefecture !== "MATOTO" && prefecture !== "RATOMA") ? <span>Prefecture de <span className='gras'>{prefecture}</span> </span>  :''  }</p>
            <p>Commune de {commune !== "COMMUNE URBAINE" ? <span className='gras'>{commune}</span>: <span className='gras'>CENTRE</span> }</p>
            <p>Agent : <strong>{ userInfo?.username && userInfo.username }</strong></p>
          </div>
          <div className="to">
            <span className="subtitle">Client </span>
            <span>Type : <strong> {paiementDetail?.typeClient}</strong></span>
            {paiementDetail?.typeClient === 'Société' && <span>NIF : <strong>{paiementDetail.nif}</strong></span>}
            <span>Nom : <strong>{paiementDetail?.typeClient === 'Particulier' ? paiementDetail?.fullName:paiementDetail?.designationOrganisation}</strong></span>
            <span>Téléphone : <strong>{paiementDetail?.tel}</strong></span>
            {paiementInfo?.status === 2 && <strong style={{color:'red'}}>Réjété</strong>} 
            {paiementInfo?.status === 1 && <span><strong style={{color:'green'}}>Validé</strong></span>} 
            {paiementInfo?.status === 4 && <span>Status : <strong style={{color:'#b1b14b'}}>Resoumis</strong></span>} 
          </div>
          <div className="details">
            <span className="subtitle">Détails </span>
            <div>
              <span>Num. Chassis : </span>
              <strong>{paiementDetail?.chassis}</strong>
            </div>
            <div>
               <span>Mode Immat. :</span>
               <strong>{modeImma}</strong>
            </div>
            <div>
               <span>Type de Paiement : </span>
               <strong>{paiementInfo?.type_paiement?paiementInfo?.type_paiement.charAt(0).toUpperCase()+paiementInfo?.type_paiement.slice(1):'Non Fournie'}</strong>
            </div>
            <div>
              <span>Date d'émission : </span>
              <strong>{moment(paiementInfo?.created_at).format('DD/MM/YYYY HH:mm')}</strong>
            </div>
          </div>
        </div>
        <div className='status'>
             <p>Status: <span className={`${paiementInfo?.status === 0 ? 'nonvalide':paiementInfo?.status === 1?"valide": paiementInfo?.status === 2?"rejete":paiementInfo?.status === 3 ? "resoumission":"resoumis"}`}>
              {paiementInfo?.status === 0 ? 'Non Validé':paiementInfo?.status === 1?"Validé": paiementInfo?.status === 2?"Réjété":paiementInfo?.status === 3 ? "Resoumission":"Resoumis"}</span></p>
           </div>
        <div className="array">
          <CartTable paiementInfo={paiementInfo} />
        </div>
        { !isNaN(sumMontant) &&  (
        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
          <div style={{paddingBottom:'10px'}}>
              <span>Total : <strong>{formatStringNumber(sumMontant)} </strong>fg</span>
          </div>
          <div><strong>{toWords.convert(sumMontant)}</strong> Francs Guinéens</div>
        </div>
         )
      }
       {paiementInfo?.qrcodepath &&
         <div className="signature">
          <div>
            <p>Signature de l'agent</p>
          </div>
          <div>
            <p>Signature du client</p>
          </div>
          <div className='qr'>
            <p>QR Code</p>
            <div className='qr-image'>
              <img src={url+paiementInfo.qrcodepath}/>
            </div>
          </div>
        </div> 
        }
        <div className="invoice-footer">
          <p>
            Pour toute information complémentaire, appelez le 8163 ou consultez
            le portail internet de SIPIM.{" "}
            <a href="https://support.sipimguinee.com">
            https://support.sipimguinee.com
            </a>
          </p>
          <p>DSD vous remercie.</p>
        </div>
        </>:
        <>{loadingtest === 2 &&
            <h2>Ce Réçu n'existe pas.</h2>
          }
        </>
       }
      </div>
  )
})

export default InvoiceDetails