import React, { useContext, useEffect,useState } from 'react'
import { UserContext } from '../../services/Context/Context';
import { ToWords } from 'to-words';
import CardTable from '../PaiementStepVadico/CardTable/CardTable';
import { formatStringNumber, isEmpty } from '../../services/Helpers/fonctions';
const InvoiceDetailsVadico =  React.forwardRef(({ paiement }, ref) => {  
  const url = process.env.REACT_APP_URL.replace('api/','') + 'storage/';
  const { agence,decoupage,user } = useContext(UserContext);
  
  const [ quartier,setQuartier ] = useState("");
  const [ commune ,setCommune ] = useState('');
  const [ prefecture,setPrefecture ] = useState('');
  const decoup = decoupage;
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  useEffect(() => {
    if( agence ){
    const getQuartier = decoup?.quartiers?.filter((q) => q.quartier_id === agence.quartier_id );
    if( getQuartier?.length > 0 ){
      setQuartier(getQuartier[0]);
      const getCommune = decoup?.communes.filter( (p) => p.commune_id === getQuartier[0].commune_id );
      if( getCommune?.length > 0 ){
          setCommune(getCommune[0]);
          const getPrefecture = decoup?.prefectures.filter((p) => p.prefecture_id === getCommune[0].prefecture_id);
          if( getPrefecture?.length > 0 ){
              setPrefecture(getPrefecture[0]);
          }
      }
    }
    }
  },[decoup]);
  
  return (
    
    <div className='invoice' ref={ref} style={{ width:'100%'}}>
        {(!isEmpty(paiement) && !isEmpty(agence) && !isEmpty(prefecture))&&
          <>
         <div className="head">
           <h1>Reçu de Paiement </h1>
           <div className="logo">{
               agence?
               <img src={ url + agence?.logo } style={{width:'85px'}}/>:
             'LOGO'}</div>
         </div>
         <div style={{ marginTop:"-40px"}}>
           <div style={{display:'inline'}}>
             <span style={{fontSize:'17px',fontFamily:'sans-serif',fontStyle:'italic'}}>Numéro de Référence : </span> 
             <span style={{fontWeight:'bold',fontFamily:'revert-layer'}}>{ paiement?.reference}</span>
           </div> 
         </div> 
         <div className="bill-details" style={{ marginTop:"-40px"}}>
            <div className="from">
                <span className="subtitle">Par </span>
                <strong>DSD Guinée</strong>
                <p>Agence de { agence.nom_agence + " / "+ quartier?.nom } </p>
                <span>Prefecture de <span className='gras'>{prefecture?.nom}</span> </span> 
                <p>Commune de {commune.nom !== "COMMUNE URBAINE" ? <span className='gras'>{commune?.nom}</span>: <span className='gras'>CENTRE</span> }</p>
                <p>Agent : <strong>{ user?.username && user.prenom + " " + user.nom }</strong></p>
            </div>
            <div className="to">
              <span className="subtitle">Client </span>
              <span>Client: <strong> { paiement?.typeClient} </strong></span>
              <span>Dénomination : <strong> { paiement?.nomComplet ? paiement?.nomComplet:paiement.nom ? paiement.nom :"------" }</strong></span>
              <span>Téléphone  : <strong> { paiement?.telephone }</strong></span>
              <span> Mode de Paiement: <strong> { paiement.mode_paiement}</strong></span>
            </div> 
          </div>  
          <div className='array' >
             <CardTable data={paiement} />
          </div>
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
              <div style={{paddingBottom:'10px'}}>
              <span>Total : <strong>{formatStringNumber(paiement?.price)} </strong>fg</span>
              </div>
              <div><strong>{toWords.convert(parseInt(paiement?.price))}</strong> Francs Guinéens</div>
          </div>
          <div className="signature">
            <div>
              <p>Signature de l'agent</p>
            </div>
            <div>
              <p>Signature du client</p>
            </div>
            <div className='qr'>
              <p>QR Code</p>
              <div className='qr-image'>
                <img src={ url + paiement.qrpath } alt='qr-code'/>
              </div>
            </div>
          </div> 
      
        <div className="invoice-footer">
            <p>
              Pour toute information complémentaire, appelez le 624 37 31 14 ou consultez
              le portail internet de SIPIM.{" "}
              <a href="https://support.sipimguinee.com">
              https://support.sipimguinee.com
              </a>
            </p>
            <p>DSD vous remercie.</p>
        </div>
        </>}
    </div>
  )
  
})
export default InvoiceDetailsVadico;
