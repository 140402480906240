import { useState } from "react";
import moment from 'moment';
import Api from "../../../services/Api";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

const BarCharts = ({ graphData,legende,titre }) => {
    const[data,setData] = useState([]);
   
    const api = new Api();
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: legende,
          },
        },
      };
    useState(() => {
    //   const getStats = async () => {
    //         const  response  = await api.apiData("get","/paiement/ct/stats");
    //         if( response.status === 200 ){
    //             setData ({
    //                 labels: response.data.payByMonthPaye?.map((p) => {
    //                  const mois = moment(p.month).format('MMMM')
    //                    return mois.charAt(0).toUpperCase()+ mois.slice(1).toLowerCase();
    //                 }),
    //                 datasets: [
    //                   {
    //                     label: 'Totaux des Montants',
    //                     backgroundColor: [
    //                       'rgba(255, 99, 132, 0.2)',
    //                       'rgba(54, 162, 235, 0.2)',
    //                       'rgba(255, 206, 86, 0.2)',
    //                       'rgba(75, 192, 192, 0.2)',
    //                       'rgba(153, 102, 255, 0.2)',
    //                       'rgba(255, 159, 64, 0.2)',
    //                     ],
    //                     borderColor: [
    //                       'rgba(255, 99, 132, 1)',
    //                       'rgba(54, 162, 235, 1)',
    //                       'rgba(255, 206, 86, 1)',
    //                       'rgba(75, 192, 192, 1)',
    //                       'rgba(153, 102, 255, 1)',
    //                       'rgba(255, 159, 64, 1)',
    //                     ],
    //                     borderWidth: 1,
    //                     hoverBackgroundColor: 'rgba(75,192,192,0.4)',
    //                     hoverBorderColor: 'rgba(75,192,192,1)',
    //                     data: response.data.payByMonthPaye?.map((p) => {
    //                       return p?.montant
    //                    }),
    //                   },
    //                 ],
    //               });
                
    //         }
    //   }
        if(graphData ){
          setData ({
            labels: graphData?.map((p) => {
             const mois = moment(p.month).format('MMMM')
               return mois.charAt(0).toUpperCase()+ mois.slice(1).toLowerCase();
            }),
            //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: titre,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                data: graphData?.map((p) => {
                  return p?.montant
               }),
              },
            ],
          });
        }
       
    },[graphData]);
   
    if( data.length === 0 ) return null;
   
   return (<Bar 
           data={data} 
           options={options} 
           width={800}
           height={520} />)
}
export default BarCharts;