import React, { useContext, useEffect, useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import { ToWords } from 'to-words';
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { formatStringNumber } from '../../services/Helpers/fonctions';
import Box from "@mui/material/Box";
import Erreurs from '../../components/Erreurs/Erreurs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import ToolbarVadis from '../../components/ToolBarPaiementListVadis/ToolBarPaiementListVadis';
import Api from '../../services/Api';
import moment from 'moment/moment';
import PayVirement from '../../components/PayVirement/PayVirement';
import { UserContext } from '../../services/Context/Context';
function PaymentListVadis() {
    const toWords = new ToWords({
        localeCode: 'fr-FR',
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
        }
      });
  const[ pageState,setPageState ] = useState({
      data:[],
      total: 0,
      page: 1,
      pageSize: 10 
  });
  const[ totalPayer,setTotalPayer ] = useState(0);
  const[ totalNonPayer,setTotalNonPayer ] = useState(0); 
  const[erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  const api = new Api();
  const [filterData, setFilterData] = useState({ items: [] });
  const[ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const[ modalOpen,setModalOpen ] = useState(false);
  const[ isPayer,setIsPayer ] = useState(true);
  const location = useLocation();
  const { privileges } = useContext(UserContext);
  
  const[_id,setId ] = useState();
  const voirReceipt = (id) => {
    setModalOpen(true);
    setIsPayer(false);
    setId(id);
  }
  const [seachData,setSearchData ] = useState({
    startDate: "",
    endDate: ""
  });
  const columns = [
    {
      field: "ordre",
      headerName: "N°",
      minWidth: 80,
    },
    {
      field: "categorie",
      headerName: "Categorie",
      minWidth: 180
    },
    {
      field: "chassis",
      headerName: "chassis",
      minWidth: 180
    },
    {
      field: "reference",
      headerName: "Référence",
      minWidth: 210
    },
    {
      field: "typeClient",
      headerName: "Type Client",
      minWidth: 210
    },
    {
      field: "fullName",
      headerName: "Nom Complet",
      minWidth: 240
    },
    {
      field: "telephone",
      headerName: "Téléphone du Client",
      minWidth: 120
    },
    {
      field: "mode_paiement",
      headerName: "Mode Paiement",
      minWidth: 150
    },
    {
     field: "montant",
     headerName: "Montant",
     minWidth: 150
    },
   
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
     
      renderCell: (params) => {
        return (
         <div className='status'>
           <div className={`${params.row.status === `validé`?"valider":params.row.status === `annulé`?"annuler":"default-status"}`}>{ params.row.status }</div>
        </div>)
      }
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 150,
    
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      hide:false,
      filterable: false ,
      sortable:false,
      minWidth: 210,
      renderCell: (params) => {
        const id = params.id;
        return (
          <div className="options">
             <button onClick={() => navigate(`/ct/paiement/facture/${id}`)}>Voir facture</button>
             {(params.row.mode_paiement.toLowerCase() === "virement crédit" && IsValidateReceiptPrivilege()) &&
               <button className='activate' onClick={() => soldPay(id)}>Payer</button>}
             { params.row.mode_paiement.toLowerCase() === "virement soldé" &&
               <button className='activate' onClick={() => voirReceipt(id)}>Voir le réçu</button>}  
          </div>
        );
      },
   }
  ];
  const IsValidateReceiptPrivilege = () => {
     return privileges.filter((p) => p.privilege === "Valider Virement Vadis").length > 0 ;
  }
  const soldPay = (id) => {
     setModalOpen(true);
     setIsPayer(true);
     setId(id)
  }
  const submitSearch = (e) => {
      e.preventDefault() 
   
      getPaiements()
  }   
  const handleInput = (e)=>{
    setSearchData({...seachData,[e.target.name]:e.target.value });
  }
  const getPaiements = async () => {
    var formdata = new FormData();
    setErreurs([]);setIsLoading(true);
     
    var url = `/paiement/ct/liste?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`;
    if( location.state?.mode_paiement){
       url = url + '&mode_paiement=' + location.state?.mode_paiement
    }
   
    if( seachData?.startDate || seachData?.endDate ){
      url = url + '&filterData='+JSON.stringify(filterData)+'&filterDate='+ JSON.stringify(seachData) ;
    }
    else if( filterData.items.length > 0){
        url = url + '&filterData='+JSON.stringify(filterData);
    }
    const { status,data,errors ,test} = await api.apiData("get",url,formdata);
    setPageState((prev) => ({...prev,data:[]}));
    //console.log(data)
    let getData = [];
    if( status === 200 ){
       if( data?.data?.length > 0 ){
        //    console.log(data.data)
           let loadedData =  data?.data;
           
            
            getData = loadedData.map((item,index) => {  
             return {
                id:item.paiement_id,
                ordre: data.from + index,
                categorie: item.name,
                chassis: item.chassis,
                reference:item.reference,
                typeClient: item.typeClient.charAt(0).toUpperCase() + item.typeClient.slice(1),
                fullName: (item.typeClient === "société" || item.typeClient === "gouvernement") ? item.nom : item.nomComplet,
                telephone: item.telephone,
                mode_paiement: item.mode_paiement.charAt(0).toUpperCase() + item.mode_paiement.slice(1),
                montant: formatStringNumber(item.price),
                status: item.status,
                date:  moment(item.pupdated_at).format("Do MMM YYYY")
             }
          });
        
      }
      setPageState((prev) => ({...prev,data: getData,total:data.total}));
      const response = await api.apiData("get","/paiement/ct/total");
      setTotalPayer(0); setTotalNonPayer(0);
      //console.log(response?.data)
      if( response.status === 200 ){
          if (response?.data?.length > 0 ){
              response?.data.map((elem) => {
                 if( elem.mode_paiement === "virement crédit" ){
                    setTotalNonPayer((prev) => prev + parseInt(elem.total_amount));
                   
                 }
                 else if(elem.mode_paiement === 'cash' || elem.mode_paiement === "virement soldé"){
                    setTotalPayer((prev) => prev + parseInt(elem.total_amount));
                 }
              })
          }
      }
      setIsLoading(false);
    }    
}
  useEffect(() => {
   console.log(filterData)
   getPaiements();  
  },[pageState.page,pageState.pageSize,filterData,modalOpen]);

  const apiRef = useGridApiRef();
  return (
    <div className='payment-list page'>
       <PayVirement 
         isOpen={modalOpen}
         setIsOpen={setModalOpen}
         setIsPayer={setIsPayer}
         isPayer={isPayer}
         id={_id} 
       /> 
       <DocumentTitle title="Liste des paiements" />
        <div className='header'>
              <h3>Liste de paiements</h3>
                <p>
                Paiements |  
                 <span>
                    <strong> Montant Payé : </strong>
                    {toWords.convert(totalPayer) + " Francs Guinéens"} (
                    <strong>{formatStringNumber(totalPayer)+ " fg"}</strong>)
                 </span>
                  / 
                  <span>
                    <strong> Montant Non Payé : </strong>
                    {toWords.convert(totalNonPayer) + " Francs Guinéens"} (
                    <strong>{formatStringNumber(totalNonPayer)+ " fg"}</strong>)
                 </span>
                </p>
        </div> 
        <div className="filters">
            <form onSubmit={submitSearch}>
                <h4>Filtres</h4>
                <div className="input-group">
                    <label>
                        Date début
                        <input type="date" name="startDate" 
                        id="startDate" 
                        placeholder="Date de début"
                        onChange={handleInput}
                        />
                    </label>
                    <label>
                        Date fin
                        <input type="date" name="endDate"
                        id="endDate" 
                        placeholder="Date de fin"
                        onChange={handleInput}
                        />
                    </label>
               </div>
               <Erreurs validation = {erreurs} />
               <button type="submit">Rechercher</button>
            </form>
       </div>
       <div className="array">
          <Box sx={{ height:'50vh',width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: ToolbarVadis }}
              // rows={paiementData}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              rowCount={pageState.total}
              rows={pageState.data}
              page={pageState.page - 1 }
              localeText={ frFR.components.MuiDataGrid.defaultProps.localeText }
              paginationMode='server'
              slots={{ toolbar: GridToolbar }}
              filterMode="server"
              onFilterModelChange={(newFilterModel) => {
                 setFilterData(newFilterModel);
              }}
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }))
              }}
              apiRef={apiRef}
              // componentsProps={{
              //   toolbar: {
              //     csvOptions: { disableToolbarButton: false },
              //     printOptions: { disableToolbarButton: false },
              //     // showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 250 },
              //   },
              // }}
              componentsProps={{ toolbar: {seachData,filterData ,isLoading,setIsLoading} }}
              // experimentalFeatures={{ newEditingApi: true }}
              />
          </Box>
        </div>  
    </div>
  )
}

export default PaymentListVadis