import React, { useEffect, useRef, useState } from 'react'
import "./NouvelleOrganisation.scss";
import { motion, AnimatePresence} from "framer-motion";
import { FaRegWindowClose } from 'react-icons/fa';
import { useForm } from "react-hook-form";
import Erreurs from '../Erreurs/Erreurs';
import { FirstUpperCase, objecttoFormData } from '../../services/Helpers/fonctions';
import Api from '../../services/Api';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import { useRecoilState } from 'recoil';
const NouvelleOrganisation = ({ isOpen, setIsOpen })=> {
  const modalRef = useRef();
  const inputRef = useRef(null);
  const [erreurs,setErreurs] = useState([]);
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState)
  const [organisationData,setOrganisationData ] = useState({
    type:'',
    designation:'',
    sigle:'',
    domaine:'',
    code:''
  })
  const { register, handleSubmit,reset, formState: { errors } } = useForm({
    defaultValues:{
      organisationData
    }});
  const api  = new Api();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const handleInput = (e) => {
     setOrganisationData({...organisationData,[e.target.name]:e.target.value});
   
       
  }
  useEffect(() => {
    if( inputRef?.current ){
      if(organisationData.type !== ''){
        inputRef.current.disabled = false;
        inputRef?.current?.focus()
        }
      else {
          inputRef.current.disabled = true;
          inputRef.current.value = "";
        }
    }
  },[organisationData?.type])
  const submitData = async () => {
     var formdata = objecttoFormData(organisationData);setErreurs([]);setIsLoading(true)
     const { status,messages } = await api.apiData('post','/organisation/nouvelle',formdata);
     setIsLoading(false)
     if( status === 200 ){
      inputRef.current.value = "";setIsOpen(false);
     }else{
          setErreurs(messages);
     }

  }

  return (
    <AnimatePresence>
        {isOpen && (
          <div className="modal-component" onClick={closeModal} ref={modalRef}>
            <motion.div initial={{ opacity: 0, top: "30%" }} animate={{ opacity: 1, top: "50%" }} transition={{ duration: 0.2 }} exit={{ opacity: 0,  top: "10%" }}
              className="modal">
                <div className="close" onClick={() => setIsOpen(false)}>
                  <FaRegWindowClose id="close-icon" />
                  
                </div>
                <h4>Ajouter une organisation ou institution</h4>
                <form onSubmit={handleSubmit(submitData)}>
                  <label>Type d'organisation
                    <select value={organisationData.type} name="type" id="type"
                      {...register('type', {
                        onChange: (e) => {
                          handleInput(e)
                        },
                        validate: (value) => value !==  ""
                        })
                      }
                    >
                    <option value=''>Selection le type d'organisation</option>
                    <option value="société">Société</option>
                    <option value="gouvernement">Gouvernement</option>
                    <option value="institution">Institution</option>
                    </select>
                    {errors.type && (
                          <span className="error-msg">Selectionner le type d'organisation.</span>
                        )}
                  </label>
                  <label>Désignation
                  <input type="text"
                     value={FirstUpperCase(organisationData.designation)} 
                     name='designation' 
                     placeholder={`Désignation de ${organisationData.type}`} 
                     autoComplete='false'
                    {...register('designation', {
                      onChange: (e) => {
                          handleInput(e)
                      },
                      required:true,minLength:3},
                    )}
                    ref={(e) => {
                      register("designation").ref(e); // Associer useForm à useRef
                      inputRef.current = e; // Stocker la référence dans inputRef
                    }}
                  />
                  {errors.designation && errors.designation.type === "required" && (
                    <span role="alert" className="error-msg">
                      Champ obligatoire.
                    </span>
                  )}
                  {errors.designation && errors.designation.type === "minLength" && (
                    <span role="alert" className="error-msg">
                      Le nombre minimum de caractères est trois (3).
                    </span>
                  )}
                  </label>
                  <label>Sigle
                    <input type="text"
                      value={organisationData.sigle} 
                      name='sigle' 
                      placeholder={`Le sigle de ${organisationData.type}`} 
                      autoComplete='false'
                      {...register('sigle', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        required:true,minLength:1,maxLength:10},
                      )}
                    
                    />
                    {errors.sigle && errors.sigle.type === "required" && (
                      <span role="alert" className="error-msg">
                        Champ obligatoire.
                      </span>
                    )}
                    {errors.sigle && errors.sigle.type === "minLength" && (
                      <span role="alert" className="error-msg">
                        Le nombre minimum de caractères est trois (3).
                      </span>
                    )}
                  </label>
                  <label>Domaine
                    <input type="text"
                      value={organisationData.domaine} 
                      name='domaine' 
                      placeholder={`Le domaine d'intervention de  ${organisationData.type}`} 
                      autoComplete='false'
                      {...register('domaine', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        required:false,minLength:2},
                      )}
                    
                    />
                    {errors.domaine && errors.domaine.type === "required" && (
                      <span role="alert" className="error-msg">
                        Champ obligatoire.
                      </span>
                    )}
                    {errors.domaine && errors.domaine.type === "minLength" && (
                      <span role="alert" className="error-msg">
                        Le nombre minimum de caractères est trois (3).
                      </span>
                    )}
                  </label>
                  <Erreurs validation = {erreurs} />
                  <button type="submit">Enregistrer</button>
                </form>  
            </motion.div>
          </div>  
        )}
    </AnimatePresence>
  )
}

export default NouvelleOrganisation