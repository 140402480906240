import React, { useEffect, useState } from 'react'
import "./ListeOrganisation.scss";
import { useNavigate } from 'react-router-dom';
import NouvelleOrganisation from '../../components/NouvelleOrganisation/NouvelleOrganisation';
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import { Box } from '@mui/material';
import { DataGridPremium ,frFR,GridToolbar} from '@mui/x-data-grid-premium';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import moment from 'moment';
import Erreurs from '../../components/Erreurs/Erreurs';
import UpdateOrganisation from '../../components/UpdateOrganisation/UpdateOrganisation';
import Swal from "sweetalert2";
const ListeOrganisation = () => {
  const navigate = useNavigate();  
  const [ addModal,setAddModal ] = useState(false);
  const [ updateModal,setUpdateModal ] = useState(false);
  const [ data,setData ] = useState([]);
  const [ erreurs,setErreurs ] = useState(false);
  const [ isLoading,setIsLoading ] = useRecoilState(loadingState);
  const[ isDelete,setIsDelete ] = useState(false);
  const [ updatedData,setUpdateData ] = useState({})
  const api = new Api();

  const deleteOrganisation = (id) => {
    
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "de supprimer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Oui,Supprimer cette Organisation`,
      cancelButtonText: "Annuler"
    }).then((result) => {
      if(result.isConfirmed){
        setIsLoading(true);setErreurs([]);
           api.apiData('delete',`/organisation/delete/${id}`).then((resp) => {
           if(resp?.status !== 200)
               setErreurs(resp?.messages);
           else setIsDelete(!isDelete);    
           })
        setIsLoading(false);
      }
    });
  }
  const _updateOrganisation = async (id) => {
    // const { status,data,messages } = await api.apiData('get',`/organisation/all?organisation_id=${id}`);
      const findData = data.filter(p => p.id === id)[0]
      if ( findData ){
        setUpdateData(findData)
        setUpdateModal(!updateModal);
       
      }
     
     }
  
  
  const columns = [
    {
      field: "ordre",
      headerName: "N°",
      minWidth: 80,
    },
    {
      field: "type_organisation",
      headerName: "Type Organisation",
      minWidth: 180
    },
    {
      field: "designation",
      headerName: "Désignation",
      minWidth: 240
    },
    {
      field: "sigle",
      headerName: "Sigle",
      minWidth: 150
    },
    {
      field: "domaine",
      headerName: "Domaine",
      minWidth: 180
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 150
    },
  
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      hide:false,
      filterable: false ,
      sortable:false,
      minWidth: 250,
      renderCell: (params) => {
        const id = params.id;
        return (
          <div className="options">
             <button className='delete' onClick={() => deleteOrganisation(id)}>Supprimer</button>
             <button onClick={() => _updateOrganisation(id)}>Modifier</button>
          </div>
        );
      },
   }
  ];
  useEffect(() => {
   const fetchData = async () => {
      setIsLoading(true);setErreurs([]);
      const { status,organisationdata,messages } = await api.apiData("get","/organisation/all"); 
      setIsLoading(false);
      if(status === 200 ){
          setData(organisationdata.map((mdata,index) => {
            return{
               id: mdata.organisation_id,
               ordre: index + 1,
               designation:mdata.nom,
               type_organisation: mdata?.type_organisation,
               sigle:mdata?.sigle,
               domaine: mdata.domaine,
               date: moment(mdata?.updated_at).format("Do MMMM YYYY")
            }   
          }))
      }else {
         setErreurs(messages)
      }
   }
   fetchData()
  },[addModal,isDelete,updateModal]);
  return (
    <div className='page organisation'>
       <DocumentTitle title="Liste des organisations" />
       <NouvelleOrganisation 
         isOpen={addModal}
         setIsOpen={setAddModal}
       />
       <UpdateOrganisation 
         isOpen={updateModal}
         setIsOpen={setUpdateModal}
         data={updatedData}
         setData={setUpdateData}
         setIsLoading={setIsLoading}
       />
       <div className="header space-between">
        <div>
          <h3>Liste des organisationss</h3>
          <p></p>
        </div>
        <button className="primary" onClick={() => setAddModal((prev) => !prev)}>
            Nouvelle Organisation
        </button>
      </div>
      <Erreurs validation={erreurs} />
      <div className="array">
         <Box sx={{ height: "100vh", width: "100%" }}>
           <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar }}
              rows={data}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />
         </Box>
      </div>   
    </div>
  )
}

export default ListeOrganisation;